const el = require('../../../lib/utils/jsx_component');
const unitSwitcher = require('./unit_switcher');
const weatherIcon = require('./weather_icon');
module.exports = ({
  weather = {},
  location = [],
  urlPrefix = ''
}) => {
  const {
    daily = [],
    current
  } = weather;
  const [today] = daily;
  const href = `${urlPrefix}/weather/`;
  return el("a", {
    className: "weather-details__banner",
    href: href,
    "data-ylk": "ll2:amp-bon;slk:See Full Forecase;elm:link;"
  }, el("div", {
    className: "weather-details__location"
  }, location && location.display_name ? el("span", null, location.display_name) : null, location && location.state ? el("span", null, `,${location.state}`) : null), el("div", {
    className: "weather-details__cta"
  }, "See Full Weather"), el("div", {
    className: "weather-details__temp-wrap"
  }, weatherIcon(today && today.icon_code), today ? el("span", {
    className: "weather-details__day--temp"
  }, unitSwitcher(current.current_temp)) : null));
};
const el = require('../../../lib/utils/jsx_component');
module.exports = ({
  urlPrefix = '',
  location = {}
}) => {
  const href = `${urlPrefix}/weather/`;
  return el("a", {
    className: "location__container location__container--toggleable",
    "data-ylk": "elm:navcat;subsec:weather",
    href: href
  }, el("div", {
    className: "location"
  }, location.display_name));
};
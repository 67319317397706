module.exports = {
  0: '00-tornado',
  1: '01-tropical-storm',
  2: '02-hurricane',
  3: '04-thunder-storms',
  4: '04-thunder-storms',
  5: '05-rain-snow',
  6: '05-rain-snow',
  7: '05-rain-snow',
  8: '05-rain-snow',
  9: '09-mixed-rain-sleet',
  10: '05-rain-snow',
  11: '11-showers',
  12: '12-rain',
  13: '13-flurries',
  14: '14-snow-showers',
  15: '14-snow-showers',
  16: '15-blowing-snow',
  17: '17-light-snow-showers',
  18: '18-sleet',
  19: '20-fog',
  20: '20-fog',
  21: '21-haze',
  22: '20-fog',
  23: '23-breezy',
  24: '24-windy',
  25: '25-frigid',
  26: '26-cloudy',
  27: '27-mostly-cloudy-night',
  28: '28-mostly-cloudy-day',
  29: '29-partly-cloudy-night',
  30: '30-partly-cloudy-day',
  31: '31-clear',
  32: '32-sunny',
  33: '33-mostly-clear',
  34: '34-mostly-sunny',
  35: '35-mixed-snow-sleet',
  36: '36-hot',
  37: '37-isolated-thunderstorms',
  38: '38-scattered-thunderstorms',
  39: '39-scattered-showers-day',
  40: '39-scattered-showers-day',
  41: '42-heavy-snow',
  42: '41-scattered-snow-showers-day',
  43: '42-heavy-snow',
  44: '44-not-available',
  45: '45-scattered-showers-night',
  46: '16-snow',
  47: '46-scattered-thunderstorms-night',
  48: 'missing-data',
  'max-temp':
    'M6.99815 2.33334L10.8075 5.898C11.0575 6.148 11.0575 6.55334 10.8075 6.80267C10.5575 7.05267 10.1528 7.05267 9.90281 6.80267L7.66481 4.56467V13.6667H6.33081V4.56467L4.09281 6.80267C3.84281 7.05267 3.43748 7.05267 3.18748 6.80267C2.93748 6.55334 2.93748 6.148 3.18748 5.898L6.99815 2.33334ZM13 0.333336C13.3682 0.333336 13.6666 0.631813 13.6666 1C13.6666 1.36819 13.3682 1.66667 13 1.66667H0.99998C0.63179 1.66667 0.333313 1.36819 0.333313 1C0.333313 0.631813 0.63179 0.333336 0.99998 0.333336H13Z',
  'min-temp':
    'M7.00185 11.6667L3.19252 8.102C2.94252 7.852 2.94252 7.44666 3.19252 7.19733C3.44252 6.94733 3.84719 6.94733 4.09719 7.19733L6.33519 9.43533L6.33519 0.333331L7.66919 0.333332L7.66919 9.43533L9.90719 7.19733C10.1572 6.94733 10.5625 6.94733 10.8125 7.19733C11.0625 7.44666 11.0625 7.852 10.8125 8.102L7.00185 11.6667ZM1.00002 13.6667C0.631831 13.6667 0.333355 13.3682 0.333355 13C0.333355 12.6318 0.631831 12.3333 1.00002 12.3333L13 12.3333C13.3682 12.3333 13.6667 12.6318 13.6667 13C13.6667 13.3682 13.3682 13.6667 13 13.6667L1.00002 13.6667Z',
  humidity:
    'M4.57862 0.733744C4.37805 0.924318 0 5.4431 0 9C0 11.5764 2.05916 13.6667 5 13.6667C7.94055 13.6667 10 11.5788 10 9C10 5.55182 5.61738 0.932934 5.41653 0.738052L5 0.333336L4.57862 0.733744ZM5.23851 10.5159C4.92326 10.5212 4.67276 10.7523 4.67876 11.0323C4.68475 11.3091 4.93895 11.5296 5.24906 11.5296H5.26019C6.78339 11.5035 8.02272 10.3821 8.02272 9.02929C8.02272 8.58808 7.89119 8.15447 7.64242 7.7751C7.48351 7.53333 7.13402 7.45198 6.86185 7.59238C6.58967 7.73328 6.49752 8.04372 6.65615 8.28549C6.80336 8.51028 6.88153 8.76725 6.88153 9.02929C6.88153 9.8334 6.14432 10.5004 5.23851 10.5159Z'
}

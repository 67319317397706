module.exports = {
  0: '602',
  1: '601',
  2: '611',
  3: '603',
  4: '603',
  5: '60E',
  6: '60E',
  7: '60E',
  8: '60E',
  9: '604',
  10: '60E',
  11: '60C',
  12: '60C',
  13: '608',
  14: '60A',
  15: '608',
  16: '60A',
  17: '614',
  18: '60E',
  19: '60B',
  20: '60B',
  21: '60B',
  22: '60B',
  23: '600',
  24: '600',
  25: '609',
  26: '605',
  27: '616',
  28: '615',
  29: '616',
  30: '615',
  31: '607',
  32: '607',
  33: '607',
  34: '607',
  35: '610',
  36: '612',
  37: '603',
  38: '60D',
  39: '60C',
  40: '60C',
  41: '60A',
  42: '60A',
  43: '608',
  44: '60F',
  45: '60C',
  46: '60A',
  47: '60D'
}

const {
  left,
  right
} = require('../../../lib/assets/icons');
const weather_icons = require('../../../lib/assets/weather_icons_new');
const el = require('../../../lib/utils/jsx_component');
const unitSwitcher = require('./unit_switcher');
const getDay = time => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const date = new Date(time * 1000);
  const day = date.getUTCDay();
  return days[day];
};
const getTime = (time, tzOffset, isDefaultTime = false) => {
  let hour;
  if (isDefaultTime) {
    hour = time;
  } else {
    const date = new Date(time * 1000 + tzOffset * 1000);
    hour = date.getUTCHours();
  }
  const pmAm = hour >= 12 ? 'PM' : 'AM';
  let formattedHour = hour > 12 ? hour - 12 : hour;
  if (hour === 0) formattedHour = 12;
  return `${formattedHour}${pmAm}`;
};
module.exports = (typeOfForecast, forecasts, location) => el("div", {
  className: `${typeOfForecast === 'hourly-forecast' ? 'hours-wrapper' : 'days-wrapper'}`
}, el("div", {
  className: `glide ${typeOfForecast === 'hourly-forecast' ? 'hourlyForecast selected' : 'dailyForecast'}`
}, el("div", {
  className: "glide__track",
  "data-glide-el": "track"
}, el("ul", {
  className: "glide__slides",
  "aria-live": "polite"
}, forecasts.map(forecast => el("li", {
  className: "glide__slide",
  "data-glide-el": "track",
  "aria-live": "polite"
}, el("span", {
  className: "time"
}, typeOfForecast === 'hourly-forecast' ? getTime(forecast.time, location.utc_offset_secs, forecast.isDefaultTime) : getDay(forecast.date)), el("img", {
  className: "wafer-img weather-image",
  alt: forecast?.description || "weather icon",
  src: `
                https://s.yimg.com/cv/apiv2/aolfp/images/weather/small/${weather_icons[forecast.icon_code]}.svg`
}), typeOfForecast === 'hourly-forecast' ? el("span", {
  className: "temp"
}, forecast.temperature ? unitSwitcher(forecast.temperature) : '--') : el("span", {
  className: "temp"
}, el("span", {
  className: "max-temp"
}, forecast.max_temp ? unitSwitcher(forecast.max_temp) : '-'), el("span", {
  className: "min-temp"
}, forecast.min_temp ? unitSwitcher(forecast.min_temp) : '-')), el("span", {
  className: "precipitation"
}, el("svg", {
  width: "8",
  height: "12",
  viewBox: "0 0 10 14",
  fill: "none",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd"
}, el("path", {
  d: weather_icons.humidity
})), el("span", {
  className: "chance-of-rain"
}, forecast.pop, "% ")))))), el("div", {
  className: "glide__arrows",
  "data-glide-el": "controls"
}, el("button", {
  type: "button",
  className: "glide__arrow glide__arrow--prev",
  "data-glide-dir": "<",
  "data-rapid": "true",
  "data-ylk": "slk:prev;elm:arrow;itc:1;ll2:amp-bon-weather;",
  title: "previous",
  "aria-label": "previous"
}, el("svg", {
  height: "15",
  width: "15",
  viewBox: "0 0 256 256",
  fill: "#fff"
}, el("path", {
  d: left
}))), el("button", {
  type: "button",
  className: "glide__arrow glide__arrow--next",
  "data-glide-dir": ">",
  "data-rapid": "true",
  "data-ylk": "slk:next;elm:arrow;itc:1;ll2:amp-bon-weather;",
  title: "next",
  "aria-label": "next"
}, el("svg", {
  height: "15",
  width: "15",
  viewBox: "0 0 256 256",
  fill: "#fff"
}, el("path", {
  d: right
}))))));
const el = require('../../../lib/utils/jsx_component');
const {
  toKebabCase
} = require('../../../lib/utils/string');
module.exports = ({
  weather = {},
  location = {},
  urlPrefix,
  slot
}) => {
  const {
    daily = []
  } = weather;
  const isNoWeather = Object.keys(weather).length && daily.length;
  const locationState = isNoWeather !== 0 ? toKebabCase(location?.display_name) || toKebabCase(location?.woeid_name) : '';
  const locationCountry = isNoWeather !== 0 ? toKebabCase(location?.country_code) : '';
  const detailLocationHref = `${urlPrefix}/weather/forecast/${locationCountry}/${locationState}-${location.woeid}/`;
  return el("div", {
    class: isNoWeather === 0 ? 'm-weather-card__bottom-nav fallback' : 'm-weather-card__bottom-nav'
  }, el("img", {
    alt: "",
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/AccuWeather_2.svg",
    width: "85",
    height: "12"
  }), el("a", {
    href: detailLocationHref,
    class: "weather-details__link",
    "data-ylk": "slk:See Full Forecast;elm:location;itc:0;sec:weather"
  }, "See full forecast"));
};
const weather_icons = require('../../../lib/assets/weather_icons_new');
const el = require('../../../lib/utils/jsx_component');
const unitSwitcher = require('./unit_switcher');
module.exports = ({
  weather = {},
  location = {},
  slot
}) => {
  const {
    daily = [],
    current = {},
    hourly = []
  } = weather;
  const [today] = daily;
  const {
    condition_code,
    description
  } = current;
  const iconUrl = `https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/${weather_icons[condition_code]}.svg`;
  const weather_placeholder_start = '--° ';
  const weather_placeholder_end = ' --%';
  const forecastLength = slot === 'cw_full' ? 5 : 4;
  const getTime = (time, tzOffset, isDefaultTime = false) => {
    let hour;
    if (isDefaultTime) {
      hour = time;
    } else {
      const date = new Date(time * 1000 + tzOffset * 1000);
      hour = date.getUTCHours();
    }
    const pmAm = hour >= 12 ? ' PM' : ' AM';
    let formattedHour = hour > 12 ? hour - 12 : hour;
    if (hour === 0) formattedHour = 12;
    return `${formattedHour}${pmAm}`;
  };
  return el("div", {
    className: "fh"
  }, Object.keys(weather).length && daily.length ? el("div", {
    className: "fh"
  }, el("div", {
    className: "weather-details"
  }, el("div", {
    className: "weather-details__current "
  }, el("div", {
    className: "current_weather"
  }, el("div", {
    className: "weather-details__current--temp"
  }, unitSwitcher(current.current_temp || 0), el("div", {
    className: "weather-details__current__max-min"
  }, el("div", {
    className: "weather-details__details__max-min--max"
  }, el("span", {
    className: "high"
  }, "H"), " ", unitSwitcher(today?.max_temp)), el("div", {
    className: "weather-details__details__max-min--min"
  }, el("span", {
    className: "low"
  }, "L ", ' '), " ", unitSwitcher(today?.min_temp)))), el("div", {
    className: "weather-details__current__weather"
  }, el("div", {
    className: "weather-details__current__weather--icon"
  }, condition_code ? el("img", {
    src: iconUrl,
    alt: description || 'weather icon',
    className: "weather-icon"
  }) : '', el("div", {
    className: "desc"
  }, current.description || '')), el("div", {
    className: "weather-details__current__weather--desc"
  }, current.description || ''))), el("div", {
    className: "weather-details__current__max-min"
  }, el("div", {
    className: "weather-details__details__max-min--max"
  }, el("span", {
    className: "high"
  }, "H"), unitSwitcher(today?.max_temp)), el("div", {
    className: "weather-details__details__max-min--min"
  }, el("span", {
    className: "low"
  }, "L ", ' '), unitSwitcher(today?.min_temp)))), el("div", {
    className: "weather-details__forecast"
  }, hourly.slice(0, forecastLength).map(hour => el("div", {
    className: "weather-details__forecast__wrapper"
  }, el("div", {
    className: "weather-details__forecast__wrapper--time"
  }, getTime(hour.time, location.utc_offset_secs, hour.isDefaultTime)), el("div", {
    className: "weather-details__forecast__wrapper--icon"
  }, el("img", {
    className: "wafer-img weather-image",
    alt: hour?.description || 'weather icon',
    src: `
                https://s.yimg.com/cv/apiv2/aolfp/images/weather/small/${weather_icons[hour.icon_code]}.svg`
  })), el("div", {
    className: "weather-details__forecast__wrapper--temp"
  }, el("div", {
    className: "temperature"
  }, unitSwitcher(hour.temperature)), el("div", {
    className: "precipitation"
  }, hour.pop, "%"))))))) : el("div", {
    className: "fh flex"
  }, el("div", {
    className: "weather-details--fallback"
  }, el("div", {
    className: "icon-container"
  }, el("div", {
    className: "icon"
  }, el("img", {
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/missing-data.svg",
    alt: "weather icon",
    className: "weather-icon"
  })), el("div", {
    className: "label"
  }, "No Data")), el("div", {
    className: "weather-default-info"
  }, el("div", {
    className: "heading"
  }, "Weather forecast not available now."), el("div", {
    className: "sub-heading"
  }, "We\u2019re working on this and should have things up and running soon."))), el("div", {
    className: "bottom-container"
  }, el("div", null, Array(4).fill(0).map(() => el("div", {
    className: "empty-weather"
  }, weather_placeholder_start, ' ', weather_placeholder_end))))));
};
import locationPill from '../../renderers/location_pill'

export default (() => {
  class Location {
    constructor(location, pill) {
      this.location = location
      this.pill = pill || locationPill(location)
      this.isHome = location?.isHome
    }

    setActive() {
      this.pill.classList.add('active')
      this.location.isActive = true
    }

    setInactive() {
      this.pill.classList.remove('active')
      this.location.isActive = false
    }

    setHomeLocation() {
      this.isHome = true
      this.location.isHome = true
      this.pill.querySelector('svg').classList.remove('display-hidden')
    }

    removeHomeLocation() {
      this.isHome = false
      this.location.isHome = false
      this.pill.querySelector('svg').classList.add('display-hidden')
    }

    showCrossBtn() {
      this.crossBtn?.classList.remove('display-hidden')
    }

    hideCrossBtn() {
      this.crossBtn?.classList.add('display-hidden')
    }

    addCrossBtnClass() {
      this.pill.classList.add('hasCrossBtn')
    }

    removeCrossBtnClass() {
      this.pill.classList.remove('hasCrossBtn')
    }

    get isActive() {
      return this.pill.classList.contains('active') || this.location?.isActive
    }

    get getWoeid() {
      return this.location.woeid
    }

    get crossBtn() {
      return this.pill.querySelector('.default-btn__cross')
    }

    get hasCrossBtn() {
      return !this.crossBtn?.classList.contains('display-hidden')
    }
  }

  return Location
})()

const el = require('../../../lib/utils/jsx_component');
const convertToCelsius = temp => Math.round((temp - 32) * 5 / 9);
const FAHRENHEIT_UNIT = 'F';
const CELSIUS_UNIT = 'C';
module.exports = (imperial, metric, imperialUnit = '°', metricUnit = '°') => el("span", null, el("span", {
  className: "weather__unit--imperial",
  "aria-label": `${imperial}${imperialUnit} fahrenheit`
}, el("span", {
  "aria-hidden": "true"
}, imperial, el("span", {
  className: "weather__unit--imperial--degree"
}, imperialUnit), el("span", {
  className: "weather__unit--imperial--unit"
}, FAHRENHEIT_UNIT))), el("span", {
  className: "weather__unit--metric",
  "aria-label": `${metric || convertToCelsius(imperial)}${metricUnit} celsius`
}, el("span", {
  "aria-hidden": "true"
}, metric || convertToCelsius(imperial), el("span", {
  className: "weather__unit--metric--degree"
}, metricUnit), el("span", {
  className: "weather__unit--metric--unit"
}, CELSIUS_UNIT))));
const el = require('../../../lib/utils/jsx_component');
const weatherIcon = require('./weather_icon');
const unitSwitcher = require('./unit_switcher');
module.exports = ({
  weather = {},
  urlPrefix = ''
}) => {
  const {
    daily = [],
    current
  } = weather;
  const [today] = daily;
  const href = `${urlPrefix}/weather/`;
  return el("a", {
    className: "weather-details__link weather-details__day",
    href: href,
    "data-ylk": "slk:See Full Forecast;elm:navcat;subsec:weather",
    title: "aol weather"
  }, weatherIcon(today && today.icon_code), today ? el("span", {
    className: "weather-details__day--temp"
  }, unitSwitcher(current.current_temp)) : null);
};
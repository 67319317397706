const el = require('../../../lib/utils/jsx_component');
const weatherIcon = require('./weather_icon');
const unitSwitcher = require('./unit_switcher');
module.exports = ({
  weather,
  urlPrefix = '',
  isAccuWeather
}) => {
  const {
    daily = [],
    current
  } = weather;
  const [today, tomorrow] = daily;
  const href = `${urlPrefix}/weather/`;
  return today && tomorrow ? el("div", null, el("div", {
    className: "weather-details"
  }, el("a", {
    href: href,
    className: "weather-details__link",
    "data-ylk": "slk:today;elm:forecast;itc:0"
  }, el("div", {
    className: "weather-details__day"
  }, el("div", {
    className: "weather-details__day--title"
  }, "Today"), el("div", {
    className: "weather-details__day--inner"
  }, weatherIcon(today.icon_code), el("span", {
    className: "weather-details__day--temp"
  }, unitSwitcher(current.current_temp)), el("span", null, el("div", {
    className: "weather-details__day--temp-max"
  }, unitSwitcher(today.max_temp)), el("div", {
    className: "weather-details__day--temp-min"
  }, unitSwitcher(today.min_temp)))))), el("a", {
    href: href,
    className: "weather-details__link",
    "data-ylk": "slk:tomorrow;elm:forecast;itc:0"
  }, el("div", {
    className: "weather-details__day"
  }, el("div", {
    className: "weather-details__day--title"
  }, "Tomorrow"), el("div", {
    className: "weather-details__day--inner"
  }, weatherIcon(tomorrow.icon_code), el("span", null, unitSwitcher(tomorrow.max_temp), "/", unitSwitcher(tomorrow.min_temp)))))), el("a", {
    href: href,
    className: "weather-details__link",
    "data-ylk": "slk:See Full Forecast;elm:forecast;itc:0"
  }, el("span", {
    className: "weather-details__full"
  }, "See full forecast")), isAccuWeather ? el("img", {
    alt: "Powered by AccuWeather",
    src: "https://s.yimg.com/cv/apiv2/default/20200311/AW_Black_Small_R.png",
    width: "70",
    height: "10"
  }) : null) : el("p", null, "Weather forecast not available");
};
const weather_icons = require('../../../lib/assets/weather_icons');
const weather_icon_codes = require('../../../lib/assets/weather_icon_codes');
const el = require('../../../lib/utils/jsx_component');
module.exports = iconCode => {
  return el("span", {
    className: "weather-details__day--icon",
    alt: "Weather Icon"
  }, el("svg", {
    height: "24",
    width: "24",
    viewBox: "0 0 256 256",
    fill: "#fff"
  }, el("path", {
    d: iconCode ? weather_icons[`UniE${weather_icon_codes[iconCode]}-g`] : weather_icons['AolWeather']
  })));
};
const el = require('../../../lib/utils/jsx_component');
module.exports = suggestions => {
  if (suggestions && suggestions.ApiData || !Array.isArray(suggestions)) {
    return;
  }
  const list = suggestions.map(({
    woeid,
    full_display_name
  }) => el("li", {
    className: "location-form__suggestions--item",
    tabindex: "0",
    "data-woeid": woeid
  }, full_display_name)).splice(0, 10);
  return el("ul", {
    className: "location-form__suggestions--inner"
  }, list);
};
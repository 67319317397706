const weather_icons = require('../../../lib/assets/weather_icons_new');
const el = require('../../../lib/utils/jsx_component');
const unitSwitcher = require('./unit_switcher');
module.exports = ({
  weather = {},
  location = {},
  urlPrefix,
  isAccuWeather
}) => {
  const {
    daily = [],
    current = {},
    hourly = []
  } = weather;
  const [today] = daily;
  const {
    condition_code,
    description
  } = current;
  const iconUrl = `https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/${weather_icons[condition_code]}.svg`;
  const href = `${urlPrefix}/weather/`;
  const weather_placeholder = '--°--%';
  const getTime = (time, tzOffset, isDefaultTime = false) => {
    let hour;
    if (isDefaultTime) {
      hour = time;
    } else {
      const date = new Date(time * 1000 + tzOffset * 1000);
      hour = date.getUTCHours();
    }
    const pmAm = hour >= 12 ? ' PM' : ' AM';
    let formattedHour = hour > 12 ? hour - 12 : hour;
    if (hour === 0) formattedHour = 12;
    return `${formattedHour}${pmAm}`;
  };
  return el("div", null, Object.keys(weather).length && daily.length ? el("div", null, el("div", {
    className: "weather-details"
  }, el("div", {
    className: "weather-details__current"
  }, el("div", {
    className: "weather-details__current--temp"
  }, unitSwitcher(current.current_temp || 0), el("div", {
    className: "weather-details__current__max-min"
  }, el("div", {
    className: "weather-details__details__max-min--max"
  }, unitSwitcher(today?.max_temp)), el("div", {
    className: "weather-details__details__max-min--min"
  }, unitSwitcher(today?.min_temp)))), el("div", {
    className: "weather-details__current__weather"
  }, el("div", {
    className: "weather-details__current__weather--icon"
  }, condition_code ? el("img", {
    src: iconUrl,
    alt: description || 'weather icon',
    class: "weather-icon"
  }) : '')), el("div", {
    className: "weather-details__current__weather--desc"
  }, current.description || ''), el("div", {
    className: "weather-details__current__max-min"
  }, el("div", {
    className: "weather-details__details__max-min--max"
  }, unitSwitcher(today?.max_temp)), el("div", {
    className: "weather-details__details__max-min--min"
  }, unitSwitcher(today?.min_temp)))), el("div", {
    className: "weather-details__forecast"
  }, hourly.slice(0, 3).map(hour => el("div", {
    className: "weather-details__forecast__wrapper"
  }, el("div", {
    className: "weather-details__forecast__wrapper--time"
  }, getTime(hour.time, location.utc_offset_secs, hour.isDefaultTime)), el("div", {
    className: "weather-details__forecast__wrapper--icon"
  }, el("img", {
    className: "wafer-img weather-image",
    alt: hour?.description || 'weather icon',
    src: `
                https://s.yimg.com/cv/apiv2/aolfp/images/weather/small/${weather_icons[hour.icon_code]}.svg`
  })), el("div", {
    className: "weather-details__forecast__wrapper--temp"
  }, el("div", {
    className: "temperature"
  }, unitSwitcher(hour.temperature)), el("div", {
    className: "precipitation"
  }, hour.pop, "%"))))), el("div", {
    class: "m-weather-card__bottom-nav"
  }, el("a", {
    href: href,
    className: "weather-details__link",
    "data-ylk": "slk:See Full Forecast;elm:forecast;itc:0"
  }, el("span", null, "See full forecast >")), isAccuWeather && el("img", {
    alt: "AccuWeather",
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/AccuWeather_2.svg",
    width: "85",
    height: "12"
  }))), el("div", {
    className: "m-weather-card__separator-wrapper"
  }, el("hr", {
    class: "m-weather-card__separator"
  }))) : el("div", {
    className: "right-rail"
  }, el("div", {
    class: "weather-details--fallback"
  }, el("div", {
    class: "icon-container"
  }, el("div", {
    class: "icon"
  }, el("img", {
    src: "https://s.yimg.com/cv/apiv2/aolfp/images/weather/large/missing-data.svg",
    alt: "weather icon",
    class: "weather-icon"
  })), el("div", {
    class: "label"
  }, "No Data")), el("div", {
    class: "weather-default-info"
  }, el("div", {
    class: "heading"
  }, "Weather forecast not available now."), el("div", {
    class: "sub-heading"
  }, "We\u2019re working on this and should have things up and running soon."))), el("div", {
    class: "bottom-container"
  }, Array(4).fill(0).map(() => {
    return el("div", {
      class: "empty-weather"
    }, weather_placeholder);
  }))));
};